.button {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__loading {
  content: '';
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
