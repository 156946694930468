.signInContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 20px 20px;
  font-family: 'Lato';
}

.formContainer {
  display: flex;
  width: 100%;
  max-width: 1000px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
}

.leftPanel {
  flex: 1;
  padding: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.rightPanel {
  flex: 1;
  background: linear-gradient(135deg, #f7f8fa 0%, #becfe9 100%);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-top: 60px;
  color: #000;
}

.rightPanelContent {
  max-width: 400px;
  margin: 0 auto;

  h2,
  div {
    color: #000;
    font-size: 22px;
  }
}

.title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #000;
}

.welcomeText {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 40px;
  color: #000;
}

.formGroup {
  margin-bottom: 20px;
  overflow: hidden;
}

.passwordFormGroup {
  max-height: 0;
  opacity: 0;
  margin-bottom: 0;
  transition: all 0.2s linear;
  overflow: hidden;

  &.visible {
    max-height: 60px;
    opacity: 1;
    margin-bottom: 20px;
  }
}

.errorMessage {
  color: #d32f2f;
  background-color: #ffebee;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.formControl {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #0078d7;
    outline: none;
  }
}

.signInButton {
  width: 100%;
  padding: 12px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 52px;
}

.secureCodeMessage {
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 12px 15px;
  margin: 15px 0;
  font-size: 14px;
  color: #555;
  text-align: left;
  line-height: 1.5;

  a {
    color: #0078d7;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

.orDivider {
  position: relative;
  text-align: center;
  margin: 20px 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background-color: #ddd;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  span {
    display: inline-block;
    padding: 0 10px;
    background-color: white;
    position: relative;
    color: #888;
    font-size: 14px;
  }
}

.googleButton {
  width: 100%;
  padding: 12px;
  background-color: white;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  text-decoration: none;
  min-height: 52px;

  &:hover {
    background-color: #f5f5f5;
  }
  span {
    padding-left: 10px;
  }
}

.googleIcon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.signUpButton,
.postProjectButton {
  display: inline-block;
  padding: 12px 24px;
  background-color: white;
  color: #000;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
}

.hireSection {
  margin-top: 60px;

  p {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .formContainer {
    flex-direction: column;
  }

  .rightPanel {
    padding: 30px;
  }
}

.forgotPasswordContainer {
  text-align: right;
  margin-bottom: 15px;
}
